import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  DatePicker,
  Select,
  Modal,
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createCompanyCoupon,
  companyCoupon,
  updateCompanyCoupon,
} from "../../../../store/Admin/actions/CompanyCoupon";
import Loading from "../../../../components/Admin/Loading/Loading";
import dayjs from "dayjs";
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { getAdvisersList } from "../../../../store/Admin/actions/users";

const { Option } = Select;

const CompanyCouponCreate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState(false);
  const { dataCoupon, loading: loadingCoupon } = useSelector((state) => state.companyCoupon);
  const { loading: loadingUpdate } = useSelector((state) => state.companyCouponUpdate);
  const { loading: loadingCreate } = useSelector((state) => state.companyCouponCreate);
  const { dataAdvisers, loading } = useSelector((state) => state.advisersList);

  const [advisers, setAdvisers] = useState([]);

  const changedAdvisersArr = (arr, index, key, value) => {
    const isEmptyAdvisersArr = !arr[index]?.adviser_id && !arr[index]?.session_price;
    arr[index] = !isEmptyAdvisersArr ? arr[index] : {};
    arr[index][key] = value;

    const result = arr.filter((item) => item.adviser_id);
    return result;
  };

  function hasDuplicates(array) {
    const counts = {};
  
    for (const num of array) {
      if (counts[num]) {
        return true;
      }
      counts[num] = 1;
    }
  
    return false;
  }

  useEffect(() => {
      dispatch(getAdvisersList());
      if (id) {
        setIsUpdate(true);
        dispatch(companyCoupon(id));
      }
  }, [dispatch]);

  const onFinish = (values) => {
    values = { ...values, advisers };

    const advisers_ids = advisers.map((adviser) => +adviser.adviser_id);
    const hasDuplication = hasDuplicates(advisers_ids);
    if (!hasDuplication) {
      if (id) {
        dispatch(
          updateCompanyCoupon({
            id: id,
            values,
            callback: () => {
              dispatch(companyCoupon(id));
              navigate(`/admin/company-coupons/${id}/edit`);
            },
          })
        );
      } else {
        dispatch(
          createCompanyCoupon({
            ...values,
            callback: () => {
              form.resetFields();
              navigate(`/admin/company-coupons`);
            },
          })
        );
      }
    } else {
      Modal.error({
        title: "حدث خطأ ما!",
        content: "بسبب تكرار بعض المستشارين",
      });
    }
  };
  
  useEffect(() => {
    if (isUpdate) {
      const start_date = new Date(dataCoupon.start_date);
      start_date.setDate(start_date.getDate() + 1);
      dataCoupon.start_date = dayjs(start_date);
      
      const end_date = new Date(dataCoupon.end_date);
      end_date.setDate(end_date.getDate() + 1);
      dataCoupon.end_date = dayjs(end_date);

      const advisersData = dataCoupon?.advisers?.map((adviser) => {
        return { adviser_id: adviser.id, session_price: adviser.price };
      });

      setAdvisers(advisersData);
      form.setFieldsValue(dataCoupon);
    }
  }, [form, dataCoupon]);

  return (
    <Card>
      {loadingCoupon ? (
        <Loading />
      ) : (
        <Form
          size="large"
          form={form}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={onFinish}
          autoComplete="off"
          // scrollToFirstError={{
          //   behavior: "smooth",
          //   block: "center",
          // }}
        >
          <Row gutter={20} justify="center">
            <Col span={24} md={10}>
              <Form.Item
                name="name"
                rules={[
                  { required: false },
                ]}
              >
                <Input placeholder="اسم الشركة" />
              </Form.Item>
              <Form.Item
                name="code"
                rules={[
                  { required: true },
                ]}
              >
                <Input placeholder="الكود" />
              </Form.Item>
              <Form.Item
                name="no_of_beneficiaries"
                rules={[
                  { required: false },
                ]}
              >
                <InputNumber placeholder="عدد المستفيدين" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                name="sessions_limit"
                rules={[
                  { required: false },
                ]}
              >
                <InputNumber placeholder="عدد الحجوزات المتاحة (لكل شخص باستخدام هذا الكوبون)" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                name="start_date"
              >
                <DatePicker
                  placeholder="تاريخ بداية الصلاحية"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                name="end_date"
              >
                <DatePicker
                  placeholder="تاريخ نهاية الصلاحية"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.List name="advisers">
                {(items, { add, remove }) => (
                  <>
                    {items.map(({ key, name, ...restField }) => (
                      <Form.Item
                        {...restField}
                        key={key}
                        rules={[{ required: true, message: 'Input required' }]}
                      >
                        <Form.Item
                          name="adviser_id"
                          rules={[]}
                          style={{ display: 'inline-block', width: 'calc(70% - 6px)' }}
                        >
                          <Select showSearch filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                            onChange={
                              (adviser_id) => {
                                const data = changedAdvisersArr(advisers, key, 'adviser_id', adviser_id);
                                setAdvisers(data);
                              }
                            }
                            value={advisers[key]?.adviser_id}
                            placeholder="المستشار"
                            loading={loading}
                          >
                            {dataAdvisers?.map((item, index) => (
                              <Option key={index} value={item.id}>
                                {item.full_name_ar}
                              </Option>
                            ))}
                          </Select>
                          {items.length > 0 && (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => {
                                if (advisers[key]?.adviser_id) {
                                  items = items.filter((item) => item['key'] != key);
                                  const filteredAdvisers = advisers.filter((adviser) => {
                                    return adviser.adviser_id != advisers[key].adviser_id;
                                  });

                                  setAdvisers(filteredAdvisers);
                                  return remove(items.length);
                                }
                                
                                return remove(key);
                              }}
                            />
                          )}
                        </Form.Item>
                        <Form.Item
                          key={key}
                          rules={[]}
                          style={{ display: 'inline-block', width: 'calc(30% - 6px)', margin: '0 6px' }}
                        >
                          <InputNumber
                            onChange={
                              (session_price) => {
                                const data = changedAdvisersArr(advisers, key, 'session_price', session_price);
                                setAdvisers(data);
                              }
                            }
                            value={!advisers[key]?.session_price ? '0' : `${+advisers[key].session_price}`}
                            placeholder="سعر كل جلسة"
                            style={{ width: "100%" }}
                            min={0}
                          />
                        </Form.Item>
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        اضافة مستشار
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item className="text-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingCreate || loadingUpdate}
                >
                   {id ? 'حفظ التعديلات' : 'إضافة '}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Card>
  );
};

export default CompanyCouponCreate;
