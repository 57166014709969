
// SESSIONS
export const GET_SESSIONS_REQUEST = 'GET_SESSIONS_REQUEST';
export const GET_SESSIONS_SUCCESS = 'GET_SESSIONS_SUCCESS'
export const GET_SESSIONS_FINALLY = 'GET_SESSIONS_FINALLY'


// SESSIONS
export const DELETE_SESSIONS_REQUEST = 'DELETE_SESSIONS_REQUEST';
export const DELETE_SESSIONS_SUCCESS = 'DELETE_SESSIONS_SUCCESS'
export const DELETE_SESSIONS_FINALLY = 'DELETE_SESSIONS_FINALLY'