
// GET_USER_CHART_REQUEST
export const GET_USER_CHART_REQUEST = 'GET_USER_CHART_REQUEST';
export const GET_USER_CHART_SUCCESS = 'GET_USER_CHART_SUCCESS'
export const GET_USER_CHART_FINALLY = 'GET_USER_CHART_FINALLY'


// GET_AGE_CHART_REQUEST
export const GET_AGE_CHART_REQUEST = 'GET_AGE_CHART_REQUEST';
export const GET_AGE_CHART_SUCCESS = 'GET_AGE_CHART_SUCCESS'
export const GET_AGE_CHART_FINALLY = 'GET_AGE_CHART_FINALLY'


// GET_SESSION_CHART_REQUEST
export const GET_SESSION_CHART_REQUEST = 'GET_SESSION_CHART_REQUEST';
export const GET_SESSION_CHART_SUCCESS = 'GET_SESSION_CHART_SUCCESS'
export const GET_SESSION_CHART_FINALLY = 'GET_SESSION_CHART_FINALLY'




// GET_SESSION_CHART_REQUEST
export const GET_ACTIVITY_CHART_REQUEST = 'GET_ACTIVITY_CHART_REQUEST';
export const GET_ACTIVITY_CHART_SUCCESS = 'GET_ACTIVITY_CHART_SUCCESS'
export const GET_ACTIVITY_CHART_FINALLY = 'GET_ACTIVITY_CHART_FINALLY'





// GET_SESSION_CHART_REQUEST
export const GET_PROFIT_CHART_REQUEST = 'GET_PROFIT_CHART_REQUEST';
export const GET_PROFIT_CHART_SUCCESS = 'GET_PROFIT_CHART_SUCCESS'
export const GET_PROFIT_CHART_FINALLY = 'GET_PROFIT_CHART_FINALLY'

