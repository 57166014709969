const environment = {
  production: { url: 'https://backend-live.malathapp.com' },
  test: { url: 'https://test.malathapp.com' },
  development: { url: 'http://localhost:4000' },
};

const getBaseUrl = (mode) => {
  const { production, test, development } = environment;

  let url = development.url;
  switch (mode) {
    case 'production':
      url = production.url;
      break;
    case 'test':
      url = test.url;
      break;
    default:
      break;
  }

  return url;
};

// change mode in both test and production servers
const mode = 'production';
export const base_url = getBaseUrl(mode);

// agora app id
export const agoraAppId = '7dddcf76c54744ffb3add8a1ab8322dc';
