import {
  CREATE_COMPANYCOUPON_FINALLY,
  CREATE_COMPANYCOUPON_REQUEST,
  CREATE_COMPANYCOUPON_SUCCESS,
  GET_COMPANYCOUPON_FINALLY,
  GET_COMPANYCOUPON_REQUEST,
  GET_COMPANYCOUPON_SUCCESS,
  GET_COMPANYCOUPONS_FINALLY,
  GET_COMPANYCOUPONS_REQUEST,
  GET_COMPANYCOUPONS_SUCCESS,
  UPDATE_COMPANYCOUPON_FINALLY,
  UPDATE_COMPANYCOUPON_REQUEST,
  UPDATE_COMPANYCOUPON_SUCCESS,
  DELETE_COMPANYCOUPON_FINALLY,
  DELETE_COMPANYCOUPON_REQUEST,
  DELETE_COMPANYCOUPON_SUCCESS,
} from "../../actions/CompanyCoupon/types";

export const companyCoupon = function (state = {}, action) {
  switch (action.type) {
    case GET_COMPANYCOUPON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANYCOUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        dataCoupon: action.payload,
      };
    case GET_COMPANYCOUPON_FINALLY:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const companyCouponCreate = function (state = {}, action) {
  switch (action.type) {
    case CREATE_COMPANYCOUPON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_COMPANYCOUPON_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_COMPANYCOUPON_FINALLY:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const companyCoupons = function (state = {}, action) {
  switch (action.type) {
    case GET_COMPANYCOUPONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANYCOUPONS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataCoupons: action.payload,
      };
    case GET_COMPANYCOUPONS_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const companyCouponDelete = function (state = {}, action) {
  switch (action.type) {
    case DELETE_COMPANYCOUPON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_COMPANYCOUPON_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_COMPANYCOUPON_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const companyCouponUpdate = function (state = {}, action) {
  switch (action.type) {
    case UPDATE_COMPANYCOUPON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_COMPANYCOUPON_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_COMPANYCOUPON_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
